import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Xing = ({ userName }) => (
  <CircleIcon href={`https://www.xing.com/profile/${userName}`} iconName="XingIcon" />
);

Xing.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Xing;
