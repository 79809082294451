import React from "react";
import PropTypes from "prop-types";

import { Nav } from "react-bootstrap";
import { Link } from "gatsby";

import "./NavItem.scss";

const HardNavItem = ({ to, onClick, children, name }) => {
  return (
    <Nav.Item>
      <Link className="nav-link cursor-pointer" to={to} smooth="easeInOutQuart" onClick={onClick}>
        {children || name || to}
      </Link>
    </Nav.Item>
  );
};

HardNavItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  name: PropTypes.string,
};

HardNavItem.defaultProps = {
  to: "",
  onClick: null,
  children: null,
  name: "",
};

export default HardNavItem;
